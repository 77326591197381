exports.components = {
  "component---src-components-layouts-blog-categories-index-tsx": () => import("./../../../src/components/layouts/blog-categories/index.tsx" /* webpackChunkName: "component---src-components-layouts-blog-categories-index-tsx" */),
  "component---src-components-layouts-blog-post-index-tsx": () => import("./../../../src/components/layouts/blog-post/index.tsx" /* webpackChunkName: "component---src-components-layouts-blog-post-index-tsx" */),
  "component---src-components-layouts-collections-post-index-tsx": () => import("./../../../src/components/layouts/collections-post/index.tsx" /* webpackChunkName: "component---src-components-layouts-collections-post-index-tsx" */),
  "component---src-components-layouts-library-categories-index-tsx": () => import("./../../../src/components/layouts/library-categories/index.tsx" /* webpackChunkName: "component---src-components-layouts-library-categories-index-tsx" */),
  "component---src-components-layouts-press-release-post-index-tsx": () => import("./../../../src/components/layouts/press-release-post/index.tsx" /* webpackChunkName: "component---src-components-layouts-press-release-post-index-tsx" */),
  "component---src-components-layouts-support-categories-index-tsx": () => import("./../../../src/components/layouts/support-categories/index.tsx" /* webpackChunkName: "component---src-components-layouts-support-categories-index-tsx" */),
  "component---src-components-layouts-support-post-index-tsx": () => import("./../../../src/components/layouts/support-post/index.tsx" /* webpackChunkName: "component---src-components-layouts-support-post-index-tsx" */),
  "component---src-components-layouts-wcag-post-index-tsx": () => import("./../../../src/components/layouts/wcag-post/index.tsx" /* webpackChunkName: "component---src-components-layouts-wcag-post-index-tsx" */),
  "component---src-pages-2022-accessibility-year-in-review-report-index-tsx": () => import("./../../../src/pages/2022-accessibility-year-in-review-report/index.tsx" /* webpackChunkName: "component---src-pages-2022-accessibility-year-in-review-report-index-tsx" */),
  "component---src-pages-2023-accessibility-year-in-review-report-index-tsx": () => import("./../../../src/pages/2023-accessibility-year-in-review-report/index.tsx" /* webpackChunkName: "component---src-pages-2023-accessibility-year-in-review-report-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adobe-xd-download-index-tsx": () => import("./../../../src/pages/adobe-xd/download/index.tsx" /* webpackChunkName: "component---src-pages-adobe-xd-download-index-tsx" */),
  "component---src-pages-adobe-xd-index-tsx": () => import("./../../../src/pages/adobe-xd/index.tsx" /* webpackChunkName: "component---src-pages-adobe-xd-index-tsx" */),
  "component---src-pages-arc-download-index-tsx": () => import("./../../../src/pages/arc/download/index.tsx" /* webpackChunkName: "component---src-pages-arc-download-index-tsx" */),
  "component---src-pages-arc-index-tsx": () => import("./../../../src/pages/arc/index.tsx" /* webpackChunkName: "component---src-pages-arc-index-tsx" */),
  "component---src-pages-brave-download-index-tsx": () => import("./../../../src/pages/brave/download/index.tsx" /* webpackChunkName: "component---src-pages-brave-download-index-tsx" */),
  "component---src-pages-brave-index-tsx": () => import("./../../../src/pages/brave/index.tsx" /* webpackChunkName: "component---src-pages-brave-index-tsx" */),
  "component---src-pages-chrome-download-index-tsx": () => import("./../../../src/pages/chrome/download/index.tsx" /* webpackChunkName: "component---src-pages-chrome-download-index-tsx" */),
  "component---src-pages-chrome-index-tsx": () => import("./../../../src/pages/chrome/index.tsx" /* webpackChunkName: "component---src-pages-chrome-index-tsx" */),
  "component---src-pages-community-index-tsx": () => import("./../../../src/pages/community/index.tsx" /* webpackChunkName: "component---src-pages-community-index-tsx" */),
  "component---src-pages-contact-sales-index-tsx": () => import("./../../../src/pages/contact-sales/index.tsx" /* webpackChunkName: "component---src-pages-contact-sales-index-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-demos-contrast-checker-index-tsx": () => import("./../../../src/pages/demos/contrast-checker/index.tsx" /* webpackChunkName: "component---src-pages-demos-contrast-checker-index-tsx" */),
  "component---src-pages-demos-teamsync-index-tsx": () => import("./../../../src/pages/demos/teamsync/index.tsx" /* webpackChunkName: "component---src-pages-demos-teamsync-index-tsx" */),
  "component---src-pages-edge-download-index-tsx": () => import("./../../../src/pages/edge/download/index.tsx" /* webpackChunkName: "component---src-pages-edge-download-index-tsx" */),
  "component---src-pages-edge-index-tsx": () => import("./../../../src/pages/edge/index.tsx" /* webpackChunkName: "component---src-pages-edge-index-tsx" */),
  "component---src-pages-error-index-tsx": () => import("./../../../src/pages/error/index.tsx" /* webpackChunkName: "component---src-pages-error-index-tsx" */),
  "component---src-pages-feature-request-index-tsx": () => import("./../../../src/pages/feature-request/index.tsx" /* webpackChunkName: "component---src-pages-feature-request-index-tsx" */),
  "component---src-pages-feedback-index-tsx": () => import("./../../../src/pages/feedback/index.tsx" /* webpackChunkName: "component---src-pages-feedback-index-tsx" */),
  "component---src-pages-figjam-download-index-tsx": () => import("./../../../src/pages/figjam/download/index.tsx" /* webpackChunkName: "component---src-pages-figjam-download-index-tsx" */),
  "component---src-pages-figjam-index-tsx": () => import("./../../../src/pages/figjam/index.tsx" /* webpackChunkName: "component---src-pages-figjam-index-tsx" */),
  "component---src-pages-figma-download-index-tsx": () => import("./../../../src/pages/figma/download/index.tsx" /* webpackChunkName: "component---src-pages-figma-download-index-tsx" */),
  "component---src-pages-figma-index-tsx": () => import("./../../../src/pages/figma/index.tsx" /* webpackChunkName: "component---src-pages-figma-index-tsx" */),
  "component---src-pages-firefox-download-index-tsx": () => import("./../../../src/pages/firefox/download/index.tsx" /* webpackChunkName: "component---src-pages-firefox-download-index-tsx" */),
  "component---src-pages-firefox-index-tsx": () => import("./../../../src/pages/firefox/index.tsx" /* webpackChunkName: "component---src-pages-firefox-index-tsx" */),
  "component---src-pages-for-compliance-managers-index-tsx": () => import("./../../../src/pages/for-compliance-managers/index.tsx" /* webpackChunkName: "component---src-pages-for-compliance-managers-index-tsx" */),
  "component---src-pages-for-designers-index-tsx": () => import("./../../../src/pages/for-designers/index.tsx" /* webpackChunkName: "component---src-pages-for-designers-index-tsx" */),
  "component---src-pages-for-developers-index-tsx": () => import("./../../../src/pages/for-developers/index.tsx" /* webpackChunkName: "component---src-pages-for-developers-index-tsx" */),
  "component---src-pages-for-product-managers-index-tsx": () => import("./../../../src/pages/for-product-managers/index.tsx" /* webpackChunkName: "component---src-pages-for-product-managers-index-tsx" */),
  "component---src-pages-gdpr-index-tsx": () => import("./../../../src/pages/gdpr/index.tsx" /* webpackChunkName: "component---src-pages-gdpr-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-library-collections-index-tsx": () => import("./../../../src/pages/library/collections/index.tsx" /* webpackChunkName: "component---src-pages-library-collections-index-tsx" */),
  "component---src-pages-library-index-tsx": () => import("./../../../src/pages/library/index.tsx" /* webpackChunkName: "component---src-pages-library-index-tsx" */),
  "component---src-pages-library-link-index-tsx": () => import("./../../../src/pages/library/link/index.tsx" /* webpackChunkName: "component---src-pages-library-link-index-tsx" */),
  "component---src-pages-mac-beta-index-tsx": () => import("./../../../src/pages/mac-beta/index.tsx" /* webpackChunkName: "component---src-pages-mac-beta-index-tsx" */),
  "component---src-pages-mac-index-tsx": () => import("./../../../src/pages/mac/index.tsx" /* webpackChunkName: "component---src-pages-mac-index-tsx" */),
  "component---src-pages-maintenance-index-tsx": () => import("./../../../src/pages/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-maintenance-index-tsx" */),
  "component---src-pages-press-releases-index-tsx": () => import("./../../../src/pages/press-releases/index.tsx" /* webpackChunkName: "component---src-pages-press-releases-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-pricing-partials-plan-feature-index-tsx": () => import("./../../../src/pages/pricing/__partials/plan-feature/index.tsx" /* webpackChunkName: "component---src-pages-pricing-partials-plan-feature-index-tsx" */),
  "component---src-pages-pricing-partials-table-divider-index-tsx": () => import("./../../../src/pages/pricing/__partials/table-divider/index.tsx" /* webpackChunkName: "component---src-pages-pricing-partials-table-divider-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-release-notes-index-tsx": () => import("./../../../src/pages/release-notes/index.tsx" /* webpackChunkName: "component---src-pages-release-notes-index-tsx" */),
  "component---src-pages-request-free-demo-index-tsx": () => import("./../../../src/pages/request-free-demo/index.tsx" /* webpackChunkName: "component---src-pages-request-free-demo-index-tsx" */),
  "component---src-pages-request-sidekick-index-tsx": () => import("./../../../src/pages/request-sidekick/index.tsx" /* webpackChunkName: "component---src-pages-request-sidekick-index-tsx" */),
  "component---src-pages-safari-download-index-tsx": () => import("./../../../src/pages/safari/download/index.tsx" /* webpackChunkName: "component---src-pages-safari-download-index-tsx" */),
  "component---src-pages-safari-index-tsx": () => import("./../../../src/pages/safari/index.tsx" /* webpackChunkName: "component---src-pages-safari-index-tsx" */),
  "component---src-pages-security-index-tsx": () => import("./../../../src/pages/security/index.tsx" /* webpackChunkName: "component---src-pages-security-index-tsx" */),
  "component---src-pages-sketch-download-index-tsx": () => import("./../../../src/pages/sketch/download/index.tsx" /* webpackChunkName: "component---src-pages-sketch-download-index-tsx" */),
  "component---src-pages-sketch-index-tsx": () => import("./../../../src/pages/sketch/index.tsx" /* webpackChunkName: "component---src-pages-sketch-index-tsx" */),
  "component---src-pages-slack-index-tsx": () => import("./../../../src/pages/slack/index.tsx" /* webpackChunkName: "component---src-pages-slack-index-tsx" */),
  "component---src-pages-stark-universal-accessibility-framework-index-tsx": () => import("./../../../src/pages/stark-universal-accessibility-framework/index.tsx" /* webpackChunkName: "component---src-pages-stark-universal-accessibility-framework-index-tsx" */),
  "component---src-pages-stark-whitepaper-q-1-2024-getaccess-index-tsx": () => import("./../../../src/pages/stark-whitepaper-q1-2024-getaccess/index.tsx" /* webpackChunkName: "component---src-pages-stark-whitepaper-q-1-2024-getaccess-index-tsx" */),
  "component---src-pages-stark-whitepaper-q-1-2024-index-tsx": () => import("./../../../src/pages/stark-whitepaper-q1-2024/index.tsx" /* webpackChunkName: "component---src-pages-stark-whitepaper-q-1-2024-index-tsx" */),
  "component---src-pages-suaf-index-tsx": () => import("./../../../src/pages/suaf/index.tsx" /* webpackChunkName: "component---src-pages-suaf-index-tsx" */),
  "component---src-pages-support-contact-us-index-tsx": () => import("./../../../src/pages/support/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-support-contact-us-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-support-privacy-index-tsx": () => import("./../../../src/pages/support/privacy/index.tsx" /* webpackChunkName: "component---src-pages-support-privacy-index-tsx" */),
  "component---src-pages-tos-index-tsx": () => import("./../../../src/pages/tos/index.tsx" /* webpackChunkName: "component---src-pages-tos-index-tsx" */)
}

